<template>
  <div>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Page Details">
      <validation-observer ref="headerDataValidation">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Page Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="page.pageTitle"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Page Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <label>Description</label>
              <b-form-textarea
                v-model="page.description"
                placeholder="Description"
                rows="3"
              />
            </b-col>
            <b-col class="mt-1" cols="12">
              <div v-if="page.bannerImg">
                <span>Banner Image</span>
                <b-img class="mb-1" fluid :src="page.bannerImg"></b-img>
              </div>
              <div class="text-center">
                <b-button
                  v-if="page.bannerImg"
                  @click="removeBannerImage(null)"
                  variant="flat-danger"
                  link
                  >Remove Banner Image</b-button
                >
                <ComponentMediaSelector
                :params="{ lang: null}"
                  buttonText="Select Banner Image"
                  :isMultiple="false"
                  @mediaSelected="handleMediaSelect"
                />
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card title="Teams">
      <b-form
        ref="teamslistForm"
        class="repeater-form"
        @submit.prevent="repeatTeamSection(null)"
      >
        <b-row
          v-for="(list, index) in page.teams"
          :id="list.id"
          :key="list.id"
          ref="listrow"
        >
          <b-col cols="6">
            <b-form-group label="Title">
              <b-form-input
                v-model="page.teams[index].title"
                placeholder="Team Title"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Subtitle">
              <b-form-input
                v-model="page.teams[index].subtitle"
                placeholder="Subtitle"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" cols="6">
            <b-form-group label="Members">
              <v-select
                multiple
                v-model="page.teams[index].members"
                :reduce="
                  (team) => {
                    return {
                      member: team._id,
                      icon: team.thumbnailImg,
                      name: team.name,
                    };
                  }
                "
                label="name"
                placeholder="Select Team Members"
                :options="teamMembers"
              />
            </b-form-group>
          </b-col>
          <b-col
            :class="index === 0 ? 'mt-1' : ''"
            md="3"
            class="align-self-center"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeTeamSectionItem(null, index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              Remove Section
            </b-button>
          </b-col>
          <b-col cols="12" v-if="page.teams[index].members.length">
            <span class="d-block">Team Member Order</span>
            <draggable
              v-model="page.teams[index].members"
              tag="ul"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(team, index) in page.teams[index].members"
                :key="index"
                tag="li"
              >
                <div class="d-flex align-items-center">
                  <b-avatar :src="team.icon" />
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ team.name }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-1"
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatTeamSection(null)"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                page.teams.length === 0 ? "Add Teams" : "Add New"
              }}</span>
            </b-button></b-col
          >
        </b-row>
      </b-form>
    </b-card>
      </b-tab>
      <!-- Hindi Translation -->
      <b-tab title="Hindi">
        <b-card title="Page Details">
      <validation-observer ref="headerDataValidation">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Page Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="page.translation.hi.pageTitle"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Page Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <label>Description</label>
              <b-form-textarea
                v-model="page.translation.hi.description"
                placeholder="Description"
                rows="3"
              />
            </b-col>
            <b-col class="mt-1" cols="12">
              <div v-if="page.translation.hi.bannerImg">
                <span>Banner Image</span>
                <b-img class="mb-1" fluid :src="page.translation.hi.bannerImg"></b-img>
              </div>
              <div class="text-center">
                <b-button
                  v-if="page.translation.hi.bannerImg"
                  @click="removeBannerImage('hi')"
                  variant="flat-danger"
                  link
                  >Remove Banner Image</b-button
                >
                <ComponentMediaSelector
                :params="{ lang: 'hi'}"
                  buttonText="Select Banner Image"
                  :isMultiple="false"
                  @mediaSelected="handleMediaSelect"
                />
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card title="Teams">
      <b-form
        ref="teamslistForm"
        class="repeater-form"
        @submit.prevent="repeatTeamSection('hi')"
      >
        <b-row
          v-for="(list, index) in page.translation.hi.teams"
          :id="list.id"
          :key="list.id"
          ref="listrow"
        >
          <b-col cols="6">
            <b-form-group label="Title">
              <b-form-input
                v-model="page.translation.hi.teams[index].title"
                placeholder="Team Title"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Subtitle">
              <b-form-input
                v-model="page.translation.hi.teams[index].subtitle"
                placeholder="Subtitle"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" cols="6">
            <b-form-group label="Members">
              <v-select
                multiple
                v-model="page.translation.hi.teams[index].members"
                :reduce="
                  (team) => {
                    return {
                      member: team._id,
                      icon: team.thumbnailImg,
                      name: team.name,
                    };
                  }
                "
                label="name"
                placeholder="Select Team Members"
                :options="teamMembers"
              />
            </b-form-group>
          </b-col>
          <b-col
            :class="index === 0 ? 'mt-1' : ''"
            md="3"
            class="align-self-center"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeTeamSectionItem('hi', index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              Remove Section
            </b-button>
          </b-col>
          <b-col cols="12" v-if="page.translation.hi.teams[index].members.length">
            <span class="d-block">Team Member Order</span>
            <draggable
              v-model="page.translation.hi.teams[index].members"
              tag="ul"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(team, index) in page.translation.hi.teams[index].members"
                :key="index"
                tag="li"
              >
                <div class="d-flex align-items-center">
                  <b-avatar :src="team.icon" />
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ team.name }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-1"
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatTeamSection('hi')"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                page.translation.hi.teams.length === 0 ? "Add Teams" : "Add New"
              }}</span>
            </b-button></b-col
          >
        </b-row>
      </b-form>
    </b-card>
      </b-tab>
      <!-- Marathi Translation  -->
      <b-tab title="Marathi">
        <b-card title="Page Details">
      <validation-observer ref="headerDataValidation">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Page Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="page.translation.mr.pageTitle"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Page Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <label>Description</label>
              <b-form-textarea
                v-model="page.translation.mr.description"
                placeholder="Description"
                rows="3"
              />
            </b-col>
            <b-col class="mt-1" cols="12">
              <div v-if="page.translation.mr.bannerImg">
                <span>Banner Image</span>
                <b-img class="mb-1" fluid :src="page.translation.mr.bannerImg"></b-img>
              </div>
              <div class="text-center">
                <b-button
                  v-if="page.translation.mr.bannerImg"
                  @click="removeBannerImage('mr')"
                  variant="flat-danger"
                  link
                  >Remove Banner Image</b-button
                >
                <ComponentMediaSelector
                :params="{ lang: 'mr'}"
                  buttonText="Select Banner Image"
                  :isMultiple="false"
                  @mediaSelected="handleMediaSelect"
                />
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card title="Teams">
      <b-form
        ref="teamslistForm"
        class="repeater-form"
        @submit.prevent="repeatTeamSection('mr')"
      >
        <b-row
          v-for="(list, index) in page.translation.mr.teams"
          :id="list.id"
          :key="list.id"
          ref="listrow"
        >
          <b-col cols="6">
            <b-form-group label="Title">
              <b-form-input
                v-model="page.translation.mr.teams[index].title"
                placeholder="Team Title"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Subtitle">
              <b-form-input
                v-model="page.translation.mr.teams[index].subtitle"
                placeholder="Subtitle"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" cols="6">
            <b-form-group label="Members">
              <v-select
                multiple
                v-model="page.translation.mr.teams[index].members"
                :reduce="
                  (team) => {
                    return {
                      member: team._id,
                      icon: team.thumbnailImg,
                      name: team.name,
                    };
                  }
                "
                label="name"
                placeholder="Select Team Members"
                :options="teamMembers"
              />
            </b-form-group>
          </b-col>
          <b-col
            :class="index === 0 ? 'mt-1' : ''"
            md="3"
            class="align-self-center"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeTeamSectionItem('mr',index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              Remove Section
            </b-button>
          </b-col>
          <b-col cols="12" v-if="page.translation.mr.teams[index].members.length">
            <span class="d-block">Team Member Order</span>
            <draggable
              v-model="page.translation.mr.teams[index].members"
              tag="ul"
              class="list-group list-group-flush cursor-move"
            >
              <b-list-group-item
                v-for="(team, index) in page.translation.mr.teams[index].members"
                :key="index"
                tag="li"
              >
                <div class="d-flex align-items-center">
                  <b-avatar :src="team.icon" />
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ team.name }}
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-1"
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatTeamSection('mr')"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                page.translation.mr.teams.length === 0 ? "Add Teams" : "Add New"
              }}</span>
            </b-button></b-col
          >
        </b-row>
      </b-form>
    </b-card>
      </b-tab>
    </b-tabs>
    <b-card>
      <b-button @click="savePage" variant="primary" size="lg" block
        >Save Page</b-button
      >
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { getUserToken } from "@/auth/utils";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import {
  BCard,
  BRow,
  BCol,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BForm,
  BTabs, BTab,
  BButton,
  BImg,
  BListGroupItem,
  BAvatar,
  BCardText,
} from "bootstrap-vue";
import { required } from "@validations";
import axios from "axios";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BForm,
  BTabs, BTab,
    BButton,
    ComponentMediaSelector,
    BImg,
    vSelect,
    BListGroupItem,
    draggable,
    Prism,
    BAvatar,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      bannerImage: "",
      teamMembers: [],
      page: {
        pageTitle: "",
        description: "",
        bannerImg: "",
        teams: [],
        translation: {
          hi: {
            pageTitle: "",
        description: "",
        bannerImg: "",
        teams: [],
          },
          mr: {
            pageTitle: "",
        description: "",
        bannerImg: "",
        teams: [],
          }
        }
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    removeBannerImage(lang=undeifned) {
      if(lang) {
      this.page.translation[lang].bannerImg = "";
      } else {
      this.page.bannerImg = "";
      }
    },
    repeatTeamSection(lang=undefined) {
      if(lang) {
this.page.translation[lang].teams.push({
        id: this.page.translation[lang].teams.length + 1,
        title: "",
        subtitle: "",
        members: [],
      });
      } else {
this.page.teams.push({
        id: this.page.teams.length + 1,
        title: "",
        subtitle: "",
        members: [],
      });
      }
    },
    removeTeamSectionItem(lang=undefined, index) {
      if(lang) {
      this.page.translation[lang].teams.splice(index, 1);
      } else {
      this.page.teams.splice(index, 1);
      }
    },
    getTeam() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/org/team/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.teamMembers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    savePage() {
      this.$refs.headerDataValidation.validate().then((success) => {
        if (success) {
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/pages/organization/save`,
              this.page,
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              console.log(response.data);
              this.makeToast("success", "Success", "Page saved successfully!");
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast("danger", "Error", "Couldn't save the data.");
            });
        }
      });
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/organization/cms/get`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log(response.data)
          const { teams, translation } = response.data;
          const pageTeams = [];
          const pageTeamsHi = [];
          const pageTeamsMr = [];
          for (let i = 0; i < teams.length; i++) {
            const team = teams[i];
            let members = team.members.map((member) => {
              return {
                member: member.member._id,
                icon: member.member.thumbnailImg,
                name: member.member.name,
              };
            });
            team.members = members;
            pageTeams.push(team);
          }

          for (let i = 0; i < translation.hi.teams.length; i++) {
            const team = translation.hi.teams[i];
             let members = team.members.length > 0 ? team.members.map((member) => {
              return {
                member: member.member._id,
                icon: member.member.thumbnailImg,
                name: member.member.name,
              };
            }) : [];
            team.members = members;
            pageTeamsHi.push(team);
          }
          console.log(pageTeamsHi);
          for (let i = 0; i < translation.mr.teams.length; i++) {
            const team = translation.mr.teams[i];
            let members = team.members.length > 0 ? team.members.map((member) => {
              return {
                member: member.member._id,
                icon: member.member.thumbnailImg,
                name: member.member.name,
              };
            }) : [];
            team.members = members;
            pageTeamsMr.push(team);
          }
          console.log(pageTeamsMr);
          this.page = response.data;
          this.page.translation = {
            hi: {
            pageTitle: translation && translation.hi.pageTitle ? translation.hi.pageTitle : "",
        description: translation && translation.hi.description ? translation.hi.description : "",
        bannerImg: translation && translation.hi.bannerImg ? translation.hi.bannerImg : "",
        teams: pageTeamsHi? pageTeamsHi : [],
          },
          mr: {
            pageTitle: translation && translation.mr.pageTitle ? translation.mr.pageTitle : "",
        description: translation && translation.mr.description ? translation.mr.description : "",
        bannerImg: translation && translation.mr.bannerImg ? translation.mr.bannerImg : "",
        teams: pageTeamsMr? pageTeamsMr : [],
          }
          }
          this.page.teams = pageTeams;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error fetching the page data.");
        });
    },
    handleMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.page.translation[mediaObject.params.lang].bannerImg = mediaObject.media.src;
        } else {
        this.page.bannerImg = mediaObject.media.src;

        }
      }
    },
  },
  created() {
    this.getTeam();
    this.getPage();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>